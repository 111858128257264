import React from 'react';
import activityTwo from '../activity-2.png';
import './About.css';

function About() {
  return (
    <div className="about-page">
      <div className="about-hero">
        <div className="about-hero-content">
          <h1>על עמותת רגעים של אושר</h1>
          <p>
          עמותת רגעים של אושר היא עמותה שנרשמה בשנת 2021 לאחר 5 שנות פעילות של קהילה רחבה. הפעילות החלה מתוך צורך אמיתי ורצון גדול לעשות טוב לאנשים הזקוקים לרגעים של אושר בזמן מסוים בחייהם אם זה ילדים עם מוגבלויות, נוער בסיכון, חיילים, ניצולי שואה וכל מי שצריך אותנו.
קהילה שהורכבה מאנשים שתרמו לא רק מהכסף שלהם אלא גם מהזמן שלהם כדי לארוז ולחלק משלוחי מנות בפורים, לצבוע מוסדות ציבוריים של קהילות מעוטות יכולת, לחלק לחיילים שתייה, חטיפים וציוד נוסף ועוד מגוון פעילויות!
העמותה קמה מתוך רצון להרחיב את הפעילות ולהפיץ את הטוב הזה. המטרה שלנו היא להצליח ולהגיע לעוד אנשים שזקוקים לנו וכשאנחנו אומרים לנו אנחנו מתכוונים לרגע מתוק של אושר, רגע של שמחה ורגע של נתינה ללא כל תמורה!          </p>
        </div>
      </div>
    </div>
  );
}

export default About; 