import logo from './logo.png';
import './App.css';
import heroImage from './image-1.png';
import activityTwo from './activity-2.png';
import activityThree from './activity-3.png';
import heroLogo from './hero-logo.png';
import { useState } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import About from './pages/About';

function App() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <BrowserRouter>
      <div className="App">
        <header className="App-header">
          <div className="header-right">
            <button className="mobile-menu-button" onClick={toggleMobileMenu}>
              <span></span>
              <span></span>
              <span></span>
            </button>
            <nav className="nav-links">
              <Link to="/" className="nav-link">דף הבית</Link>
              <Link to="/about" className="nav-link">עלינו</Link>
              <a href="/#activities" className="nav-link">פעילויות</a>
              <div className="social-nav-links">
                <a href="https://www.facebook.com/profile.php?id=100064111031618" target="_blank" rel="noopener noreferrer" className="social-nav-link">פייסבוק</a>
              </div>
            </nav>
            <button className="header-button">לתרומה</button>
          </div>
          <div className="header-left">
            <Link to="/">
              <img src={logo} className="header-logo" alt="logo" />
            </Link>
          </div>
        </header>

        {/* Mobile Menu */}
        <div className={`mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}>
          <button className="mobile-menu-close" onClick={toggleMobileMenu}>
            ✕
          </button>
          <nav className="mobile-nav">
            <Link to="/" className="mobile-nav-link" onClick={toggleMobileMenu}>דף הבית</Link>
            <Link to="/about" className="mobile-nav-link" onClick={toggleMobileMenu}>עלינו</Link>
            <a href="/#activities" className="mobile-nav-link" onClick={toggleMobileMenu}>פעילויות</a>
            <div className="mobile-social-links">
              <a href="https://www.facebook.com/profile.php?id=100064111031618" target="_blank" rel="noopener noreferrer" className="mobile-social-link">פייסבוק</a>
            </div>
            <button className="mobile-donate-button">לתרומה</button>
          </nav>
        </div>

        <div className={`mobile-menu-overlay ${isMobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}></div>

        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/" element={
            <>
              {/* Your existing home page content */}
              <div id="home" className="hero-section">
                <div className="hero-section-title">
                  <img src={heroLogo} className="hero-logo" alt="hero-logo" />
                  <h1>מעניקים רגעים של אושר
                  לכל מי שצריך!</h1>
                  <p>אנו עמותה ללא מטרות רווח שמטרתה להעניק רגעים שמחים ומאושרים בכל מקום בכל זמן ולכל מי שזקוק לכך!
                  אנו מקפידים לשמח ילדים עם מוגבלויות, נוער בסיכון, נשים מוכות, מחוסרי דיור, חיילי צה"ל ועוד..</p>
                  <button>לתרומה</button>
                </div>
                <div className="hero-section-image">
                  <img src={heroImage} className="hero-image" alt="hero" />
                </div>
              </div>
              {/* Features Section */}
              <div className="features-section">
                <div className="feature-card">
                  <h3>40</h3>
                  <p>פעילויות שהתקיימו</p>
                </div>
                <div className="feature-card">
                  <h3>90,000₪</h3>
                  <p>נאספו עד היום לעמותה</p>
                </div>
                <div className="feature-card">
                  <h3>150</h3>
                  <p>מתנדבים בקהילה שלנו</p>
                </div>
                <div className="feature-card">
                  <h3>5,700</h3>
                  <p>מנות שנתרמו לילדים נזקקים</p>
                </div>
              </div>
              {/* About Section */}
              <div id="about" className="about-section">
                <div className="hero-section-image">
                  <img src={activityTwo} className="hero-image" alt="hero" />
                </div>
                <div className="about-section-title">
                  <h1>עלינו</h1>
                  <p>״רגעים של אושר״ הינה עמותה ללא מטרות רווח, אשר מטרתה להעניק רגעים שמחים ומאושרים - לכל מי שזקוק - בכל זמן ובכל מקום! מתנדבי ״רגעים של אושר״ כאן, על מנת לשמח ילדים עם מוגבלויות, נוער בסיכון, נשים מוכות, מחוסרי דיור, חיילי צה"ל ועוד..
                  כל המתנדבים והמייסדים שלנו פועלים בהתנדבות מלאה ללא שכר.</p>
                </div>
              </div>
              {/* Activities Section */}
              <div id="activities" className="activities-section">
                  <h1>פעילויות</h1>
                <div className="activities-section-cards">
                  <div className="activity-card">
                    <img src={heroImage} className="activity-image-1" alt="activity" />
                    <div className="activity-card-data">
                    <p>2017 - היום</p>
                    <h3>משלוחי מנות בחג הפורים</h3>
                    <p>בכל שנה בתקופת חג הפורים אנו יוצאים למשימה החשובה ביותר - לשמח!
אנו אורזים משלוחי מנות (ממש מפנקים) ויוצאים לחלק לילדים עם מוגבלויות במוסדות הלימוד, מקלטים של נוער להט״ב, נוער בסיכון, מקלטים של נשים מוכות ומקלטים של מחוסרי דיור. כל זאת ועוד באווירת חגיגות פורים, כאשר אנו מחופשים, מגיעים עם מוזיקה טובה, חיוך ענק ואנרגיות חיוביות :) אפשר להגיד שהפרויקט הוא לחלוטין ״חביב הקהל״, והצמיחה אדירה!
בשנת 2017, נתרמו כ- 180 משלוחי מנות.
חמש שנים לאחר מכן, בפורים 2021, נתרמו כ - 1,800 משלוחי מנות (!!!)
היד עוד נטויה, והתקווה הי רק לצמיחה!</p>
                    </div>
                  </div>
                  <div className="activity-card">
                    <img src={activityTwo} className="activity-image-1" alt="activity" />
                    <div className="activity-card-data">
                    <p>2021</p>
                    <h3>מבצע שומר חומות</h3>
                    <p>במבצע צבאי כוחות הבטחון וחיילי צבא ההגנה לישראל שומרים על מדינת ישראל 24/7, בנפשם ובגופם, בעת שהמדינה מותקפת פנימית וחיצונית. מתנדבי עמותת ״רגעים של אושר״ לא יכלו לשבת בשקט ויצאו לדרך עם מגוון גדול של שתייה קרה, חטיפים, מוצרי היגיינה, מוצרי סלולר ועוד. כל זה נתרם באהבה רבה ועם חיוך גדול עד לשטח בו ישנו באותם ימי כוננות.</p>
                    </div>
                  </div>
                  <div className="activity-card">
                    <img src={activityThree} className="activity-image-1" alt="activity" />
                    <div className="activity-card-data">
                    <p>2020</p>
                    <h3>מחזקים את הצוות הרפואי</h3>
                    <p>מגיפת הקורונה פקדה אותנו בשנתיים האחרונות, ולא שכחנו את הצוותים הרפואיים שעובדים מסביב לשעון עבור אוכלוסיית ישראל, החולים והבריאים.
אז לצוותים הרפואיים שבחוד החנית של התקופה הזו, שעדיין לא הסתיימה, קודם כל נגיד לכם - תודה.
בנוסף, נשלחו מאיתנו באהבה מגוון פינוקים לחיזוק והעלאת מצב הרוח- פיצות, גלידות בתרומת רשת גולדה, חטיפים, פלאפל ועוד..</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Footer Section */}
              <footer className="footer">
                <div className="footer-content">
                  <div className="footer-section">
                    <h3>צור קשר</h3>
                    <p>אימייל: info@regaim.org.il</p>
                  </div>
                  <div className="footer-section">
                    <h3>עקבו אחרינו</h3>
                    <div className="social-links">
                      <a href="https://www.facebook.com/profile.php?id=100064111031618" target="_blank" rel="noopener noreferrer" className="social-link">פייסבוק</a>
                    </div>
                  </div>
                  <div className="footer-section">
                    <h3>קישורים מהירים</h3>
                    <nav className="footer-nav">
                      <a href="#">דף הבית</a>
                      <a href="#">אודות</a>
                      <a href="#">פעילויות</a>
                      <a href="#">תרומה לעמותה</a>
                    </nav>
                  </div>
                </div>
                <div className="footer-bottom">
                  <p>© 2024 רגעים של אושר. כל הזכויות שמורות.</p>
                </div>
              </footer>
            </>
          } />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
